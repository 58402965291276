body {
  font-family: 'JetBrains Mono', 'Noto Sans Normal', monospace;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  background-color: #f8f2e4;
  width: 60%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

ol {
  list-style-position: inside;
  padding-left: 0;
}

ol li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

ul {
  list-style-position: inside;
  padding-left: 0;
}

ul.stars {
  list-style: none;
}

ul li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.footer {
  margin-top: 3em;
  font-size: 13px;
  position: fixed;
  color: #888;
  background-color: #f8f2e4;
  text-align: center;
  width: 60%;
  bottom: 0;
}

.footer hr {
  margin-top: 16px;
}

.header {
  padding-top: 10px;
  position: fixed;
  width: 60%;
  top: 0;
  background-color: #f8f2e4;
}

.header hr {
  margin-bottom: 16px;
}

.header-flex-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -0.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app {
  margin-top: 100px;
  margin-bottom: 70px;
}

@media only screen and (max-width: 600px) {
  body {
    width: 80%;
    font-size: smaller;
  }
  li {
    max-width: 90%;
  }
  .footer {
    width: 80%;
  }
  .header {
    width: 80%;
  }
}
